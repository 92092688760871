import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from '@elevio/kb-kit/lib/App';
import config from './config';
import './css/main.scss';
import DixaChat from './components/DixaChat'; // Import your component

const target = document.getElementById('kb-target');
if (!target) throw new Error('Cant find target div');

ReactDOM.render(
  <>
    <DixaChat /> {/* Include your component */}
    <App config={config} />
  </>,
  target
);