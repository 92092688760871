import * as React from 'react';
import { Trans } from '@elevio/kb-kit/lib/components/trans';
import { useTranslation } from '@elevio/kb-kit/lib/hooks';
import PageLayout from '../components/layouts/Page';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import ErrorPage from '../components/ErrorPage';
import SearchInput from '../components/searchInput/SearchInput';
function Page() {
  const { t } = useTranslation();
  return (
    <PageLayout header={<Header />} footer={<Footer />}>
      <div className="container container--vertical-padding">
        <ErrorPage title={t('error.title', 'Whoops!')}>
          <p className="error-message">
            <Trans i18nKey="error.404">
              The page you are looking for cannot be found.
            </Trans>
          </p>
        </ErrorPage>
      </div>
    </PageLayout>
  );
}
export default Page;
