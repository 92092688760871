import React, { useState } from 'react';
import cx from 'classnames';
import { Trans } from '@elevio/kb-kit/lib/components/trans';
import { useLanguages } from '@elevio/kb-kit/lib/hooks';
import SearchInput from '../searchInput/SearchInput';
import { Logo } from '../Logo';
import LoginLogout from '../LoginLogout';
import LanguagePicker from '../LanguagePicker';
import "./header.scss";
function Header({ children, className, hideSearch }) {
  const { supportedLanguages, selectedLanguage, setCurrentLanguageId } =
    useLanguages();
  return (
    <header className={cx('header', className)} data-testid="header">
      <div className="container">
        <div className="header-row">
          <a href="/" className="logo">

            

            <svg id="saxo_blog" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1400 695.75" width="335.723px" height="166.84234089px">
              <path className="cls-1" d="M444.65,250.36A50.66,50.66,0,0,0,430.13,286c0,35.23,29,56.53,66,56.53,50.9,0,70.93-37.67,70.93-37.67h2.21l0,38h83.49v-40H635.34V199.57c0-43.71-20.77-77.6-90.78-77.6-46.32,0-105.95,12.92-105.95,56.2a35.87,35.87,0,1,0,60.15-26.32s6.22-1.76,17.62-1.76c28,0,44,18.26,44,43.64v23.8C506,217.53,462.92,232.08,444.65,250.36Zm115.76,9.41c0,27.4-11.93,41-29.24,41-18.29,0-25.21-12-25.21-24.53,0-14.9,9-28.54,34.58-33.22,11.14-2.24,15.76-2.69,19.87-3.58Z" />
              <path className="cls-1" d="M312.3,308.4c-28,0-52.31-8.69-73.54-37.43H200.15v71.85h39V313.93c14.94,17.68,40.5,28.53,82.9,28.53,55.39,0,85.18-32.52,85.18-70.88,0-44.15-35.84-58.84-82.85-70.43-42.36-10.26-53.54-14.65-53.54-28.48,0-10.26,6.06-17.72,24.68-17.72,22.17,0,49.12,11.33,65.16,34.93h39.59V122.14h-40v26.73c-11.29-14.24-39-27.18-75.91-27.18-50.73,0-81.9,31.65-81.9,70,0,39.24,27.46,55.29,80.06,67.76,44.21,10.26,54.91,16,54.91,31.18C337.42,301.8,329.52,308.4,312.3,308.4Z" />
              <path className="cls-1" d="M1077.35,121.7c-67.79,0-122.47,44.83-122.47,110.38s54.68,110.38,122.47,110.38,122.48-44.82,122.48-110.38S1145.14,121.7,1077.35,121.7Zm0,187.76c-25.87,0-40.43-20.74-40.43-77.38s14.56-77.38,40.43-77.38,40.43,20.74,40.43,77.38S1103.22,309.46,1077.35,309.46Z" />
              <polygon className="cls-1" points="675.37 302.79 675.37 342.81 778.01 342.81 778.01 302.79 749.1 302.79 790.7 261.49 826 302.79 800.59 302.79 800.59 342.81 943.25 342.81 943.25 302.79 916.72 302.79 852.95 228.43 919.96 162.17 943.25 162.17 943.25 121.11 841.64 121.11 841.64 162.17 870.11 162.17 830.09 201.89 796.15 162.17 819.06 162.17 819.06 121.62 675.37 121.64 675.37 162.17 705.32 162.17 767.85 235.01 699.4 302.79 675.37 302.79" />
              <path className="cls-2" d="M506,276.28c0,12.49,6.92,24.53,25.21,24.53,17.31,0,29.24-13.64,29.24-41V239.48c-4.11.89-8.73,1.34-19.87,3.58C515,247.74,506,261.38,506,276.28Z" />
              <path className="cls-2" d="M1077.35,154.7c-25.87,0-40.43,20.74-40.43,77.38s14.56,77.38,40.43,77.38,40.43-20.74,40.43-77.38S1103.22,154.7,1077.35,154.7Z" />
              <path className="cls-3" d="M0,0V463.93H1400V0ZM282.51,259.46c-52.6-12.47-80.06-28.52-80.06-67.76,0-38.36,31.17-70,81.9-70,36.89,0,64.62,12.94,75.91,27.18V122.14h40v67.74H360.7c-16-23.6-43-34.93-65.16-34.93-18.62,0-24.68,7.46-24.68,17.72,0,13.83,11.18,18.22,53.54,28.48,47,11.59,82.85,26.28,82.85,70.43,0,38.36-29.79,70.88-85.18,70.88-42.4,0-68-10.85-82.9-28.53v28.89h-39V271h38.61C260,299.71,284.33,308.4,312.3,308.4c17.22,0,25.12-6.6,25.12-17.76C337.42,275.48,326.72,269.72,282.51,259.46ZM516.38,150.09c-11.4,0-17.62,1.76-17.62,1.76a35.85,35.85,0,1,1-60.15,26.32c0-43.28,59.63-56.2,105.95-56.2,70,0,90.78,33.89,90.78,77.6V302.79h17.45v40H569.3l0-38h-2.21s-20,37.67-70.93,37.67c-37,0-66-21.3-66-56.53a50.66,50.66,0,0,1,14.52-35.62c18.27-18.28,61.36-32.83,115.76-32.83v-23.8C560.41,168.35,544.43,150.09,516.38,150.09Zm188.94,12.08h-30V121.64l143.69,0v40.55H796.15l33.94,39.72,40-39.72H841.64V121.11H943.26v41.06H920l-67,66.25,63.77,74.37h26.54v40H800.59v-40H826l-35.3-41.3-41.6,41.3H778v40H675.37v-40h24L767.85,235Zm372,180.29c-67.79,0-122.47-44.82-122.47-110.38s54.68-110.38,122.47-110.38,122.48,44.83,122.48,110.38S1145.14,342.46,1077.35,342.46Z" />
              <path className="cls-4" d="M261.33,682.93v12.51H203.9V595.11h14.89v87.82Z" />
              <path className="cls-4" d="M404.42,683.35l-.07,12.09H349.92l-3.28-26.76H308.42L296.55,692a6.28,6.28,0,0,1-2.2,2.48,6.16,6.16,0,0,1-3.46,1H279.57L332,595.11h72.31V607.2H353L357,639h38.15v11.66H358.45l4.05,32.7Zm-59.11-25.29-6.43-51.7A75.18,75.18,0,0,1,334.76,617l-20.89,41.08Z" />
              <path className="cls-4" d="M491.4,611.46a5.76,5.76,0,0,1-1.46,1.75,3.29,3.29,0,0,1-2,.55,5.09,5.09,0,0,1-2.86-1.15c-1.08-.77-2.41-1.63-4-2.58a30.89,30.89,0,0,0-5.77-2.59,24.73,24.73,0,0,0-8-1.15,23.49,23.49,0,0,0-7.72,1.15,16.54,16.54,0,0,0-5.56,3.14,13.13,13.13,0,0,0-3.38,4.69,14.59,14.59,0,0,0-1.16,5.83,10.65,10.65,0,0,0,2.06,6.71,17.82,17.82,0,0,0,5.42,4.54,42.39,42.39,0,0,0,7.61,3.28q4.28,1.39,8.74,2.9A89,89,0,0,1,482,642a30.78,30.78,0,0,1,7.62,4.93,22.7,22.7,0,0,1,5.41,7.26,24.27,24.27,0,0,1,2.06,10.52,33.64,33.64,0,0,1-2.27,12.43,29,29,0,0,1-6.6,10.13,30.76,30.76,0,0,1-10.66,6.82,39.11,39.11,0,0,1-14.35,2.48,45.72,45.72,0,0,1-9.36-.95,46.28,46.28,0,0,1-8.6-2.65,42.92,42.92,0,0,1-7.58-4.13,37.17,37.17,0,0,1-6.32-5.41l4.26-7.13a5.83,5.83,0,0,1,1.5-1.39,3.56,3.56,0,0,1,2-.56,5.73,5.73,0,0,1,3.39,1.54c1.28,1,2.87,2.15,4.75,3.38a36.28,36.28,0,0,0,6.78,3.39,26.41,26.41,0,0,0,9.61,1.54,25.91,25.91,0,0,0,8.24-1.22,16.86,16.86,0,0,0,6.11-3.5,15,15,0,0,0,3.81-5.48,18.41,18.41,0,0,0,1.33-7.13,12,12,0,0,0-2-7.19,16.44,16.44,0,0,0-5.38-4.69,40,40,0,0,0-7.61-3.21c-2.84-.88-5.76-1.8-8.74-2.76a85.71,85.71,0,0,1-8.73-3.32,28.42,28.42,0,0,1-7.61-4.92,22.28,22.28,0,0,1-5.38-7.62,27.68,27.68,0,0,1-2-11.28,26.74,26.74,0,0,1,2.1-10.41,26.13,26.13,0,0,1,6.11-8.91,30,30,0,0,1,9.89-6.22A36,36,0,0,1,467.16,594a43.63,43.63,0,0,1,15.55,2.69A36.18,36.18,0,0,1,495,604.4Z" />
              <path className="cls-4" d="M650.2,682.93v12.51H592.77V595.11h14.89v87.82Z" />
              <path className="cls-4" d="M707.29,655.75v39.69H692.47V655.75l-36.75-60.64h13.07a4.89,4.89,0,0,1,3.14.94,7.18,7.18,0,0,1,2,2.48l21.8,37.52c.88,1.72,1.69,3.35,2.41,4.89s1.36,3,1.92,4.54c.51-1.49,1.11-3,1.78-4.54s1.46-3.17,2.34-4.89l21.73-37.52a9.23,9.23,0,0,1,1.92-2.34,4.53,4.53,0,0,1,3.11-1.08H744Z" />
              <path className="cls-4" d="M841.43,607.55H809.57v87.89H794.76V607.55h-32V595.11h78.67Z" />
              <path className="cls-4" d="M986.4,682.93v12.51H929V595.11h14.89v87.82Z" />
              <path className="cls-4" d="M1079.81,683.35l-.07,12.09h-62.18V595.11h62.18V607.2h-47.23V639h38v11.66h-38v32.7Z" />
              <path className="cls-4" d="M1197.53,595.11l-40.8,100.33h-13.35l-40.8-100.33h11.88a5,5,0,0,1,3.18,1,6.17,6.17,0,0,1,1.85,2.51l27.11,68.19q1,2.52,1.92,5.52t1.71,6.29q.7-3.29,1.54-6.29t1.81-5.52l27-68.19a5.49,5.49,0,0,1,1.78-2.44,4.91,4.91,0,0,1,3.18-1Z" />
            </svg>
          </a>
            
          <SearchInput placeholder={"Søg efter hjælp..."} data-testid="search-input" className="input-rounded"/>

          <div className="profile-nav">
            <a href='https://www.saxo.com/dk/medlemskaber' className="profile-nav-item icon icon-saxo-s">
              <span className="profile-nav-underline">Bliv medlem</span>
            </a>

            <a href='https://www.saxo.com/dk/profile' className="profile-nav-item icon icon-profile">
              <span className="profile-nav-underline">Mit Saxo</span>
            </a>

            <a href='https://www.saxo.com/dk' className="profile-nav-item icon icon-home">
              <span className="profile-nav-underline">Gå til Saxo</span>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
export default Header;
