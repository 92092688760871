import * as React from 'react';
import { useTranslation } from '@elevio/kb-kit/lib/hooks';
import * as Category from '@elevio/kb-kit/lib/components/category';
import * as Article from '@elevio/kb-kit/lib/components/article';
import * as Articles from '@elevio/kb-kit/lib/components/articles';
import { useCategories } from '@elevio/kb-kit/lib/hooks';
import * as Categories from '@elevio/kb-kit/lib/components/categories';
import PageLayout from '../components/layouts/Page';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { useBreadcrumbs } from '@elevio/kb-kit/lib/hooks';
import SearchInput from '../components/searchInput/SearchInput';
import Breadcrumb from '../components/breadcrumbs/Breadcrumbs';
import CategoryList from '../components/CategoryList';
function Page() {
  const { t } = useTranslation();
  return (
    <PageLayout header={<Header />} footer={<Footer />}>
      <div className="container container--vertical-padding">
        <div className="category">
          <div className="category-title-group">
            <h1 className="category-title"><Category.Title /></h1>
            <Breadcrumb crumbs={useBreadcrumbs()} />
          </div>

          <Categories.Loop>
            <Category.Link className="category-link" data-testid="category-link">
              <h3><Category.Title /></h3>
              <p>Antal artikler i kategorien: <Category.ArticleCount /></p>
            </Category.Link>
          </Categories.Loop>

          <Articles.Loop>
              <Article.Link className="category-link" data-testid="article-link" >
                <h3><Article.Title /></h3>
                <p>Sidst opdateret d. <Article.LastPublishedAt /></p>
              </Article.Link>
          </Articles.Loop>
        </div>

      </div>

    </PageLayout>
  );
}
export default Page;
