import React from 'react';
import { Trans } from '@elevio/kb-kit/lib/components/trans';
import { useCopy } from '@elevio/kb-kit/lib/hooks';
function ArticleActions() {
  const { onCopy, didCopy } = useCopy();
  return (
    <div className="article-actions">
      <button onClick={(e) => onCopy(e)} className="btn-icon icon icon-copy">
        {didCopy && (
          <div className="tooltip">
            <Trans i18nKey="copied">Copied</Trans>
          </div>
        )}
      </button>
      <button onClick={window.print} className="btn-icon icon icon-print"></button>
      <button className="btn-icon icon icon-share"></button>
    </div>
  );
}
export default ArticleActions;
