import React from 'react';
import cx from 'classnames';
import { Trans } from '@elevio/kb-kit/lib/components/trans';
import LoginLogout from '../LoginLogout';
import "./footer.scss";
import MarketingSubscription from '../MarketingSubscription/marketingSubscription';

function Footer({ className }) {
  return (
    <footer className={cx('footer', className)} data-testid="footer">
      <svg className="logo-s" width="988px" height="611px" viewBox="0 0 988 611" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
          <g id="Permisision/footer/4353" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.049613">
              <g id="footer-default-submission" transform="translate(0.000000, -413.000000)" fill="#FFFFFF" fillRule="nonzero">
                  <path d="M589.457743,1691.61111 C358.594442,1691.61111 220.497287,1633.15194 139.100808,1536.44126 L139.100808,1691.61111 L-76,1691.61111 L-76,1311.55315 L139.026979,1311.55315 C254.458629,1464.52254 385.726601,1511.72263 537.408294,1511.72263 C630.211354,1511.72263 673.216749,1475.74494 673.216749,1415.01195 C673.216749,1334.03463 614.375032,1302.5679 374.430764,1246.3092 C89.2293147,1178.86477 -60.1636783,1093.41316 -60.1636783,879.747463 C-60.1636783,672.866546 109.643035,502 383.474817,502 C582.813132,502 734.162594,571.681571 795.440668,648.184601 L795.440668,502 L1010.46765,502 L1010.46765,866.324592 L797.544795,866.324592 C711.534003,740.384321 564.42971,679.651331 444.457575,679.651331 C342.573548,679.651331 310.900904,719.993293 310.900904,776.362016 C310.900904,850.554552 372.031321,875.383196 600.642837,929.258052 C856.386512,992.228188 1051,1070.93169 1051,1309.31601 C1051.22126,1516.0869 890.495513,1691.61111 589.457743,1691.61111 Z" id="Path" transform="translate(487.500000, 1096.805556) rotate(-11.000000) translate(-487.500000, -1096.805556) "></path>
              </g>
          </g>
      </svg>
      <div className="container">
        <div className="mb-24">
          <a href="https://www.saxo.com/" className="logo">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -120 1400 695.75" >
              <path className="cls-1" d="M444.65,250.36A50.66,50.66,0,0,0,430.13,286c0,35.23,29,56.53,66,56.53,50.9,0,70.93-37.67,70.93-37.67h2.21l0,38h83.49v-40H635.34V199.57c0-43.71-20.77-77.6-90.78-77.6-46.32,0-105.95,12.92-105.95,56.2a35.87,35.87,0,1,0,60.15-26.32s6.22-1.76,17.62-1.76c28,0,44,18.26,44,43.64v23.8C506,217.53,462.92,232.08,444.65,250.36Zm115.76,9.41c0,27.4-11.93,41-29.24,41-18.29,0-25.21-12-25.21-24.53,0-14.9,9-28.54,34.58-33.22,11.14-2.24,15.76-2.69,19.87-3.58Z" />
              <path className="cls-1" d="M312.3,308.4c-28,0-52.31-8.69-73.54-37.43H200.15v71.85h39V313.93c14.94,17.68,40.5,28.53,82.9,28.53,55.39,0,85.18-32.52,85.18-70.88,0-44.15-35.84-58.84-82.85-70.43-42.36-10.26-53.54-14.65-53.54-28.48,0-10.26,6.06-17.72,24.68-17.72,22.17,0,49.12,11.33,65.16,34.93h39.59V122.14h-40v26.73c-11.29-14.24-39-27.18-75.91-27.18-50.73,0-81.9,31.65-81.9,70,0,39.24,27.46,55.29,80.06,67.76,44.21,10.26,54.91,16,54.91,31.18C337.42,301.8,329.52,308.4,312.3,308.4Z" />
              <path className="cls-1" d="M1077.35,121.7c-67.79,0-122.47,44.83-122.47,110.38s54.68,110.38,122.47,110.38,122.48-44.82,122.48-110.38S1145.14,121.7,1077.35,121.7Zm0,187.76c-25.87,0-40.43-20.74-40.43-77.38s14.56-77.38,40.43-77.38,40.43,20.74,40.43,77.38S1103.22,309.46,1077.35,309.46Z" />
              <polygon className="cls-1" points="675.37 302.79 675.37 342.81 778.01 342.81 778.01 302.79 749.1 302.79 790.7 261.49 826 302.79 800.59 302.79 800.59 342.81 943.25 342.81 943.25 302.79 916.72 302.79 852.95 228.43 919.96 162.17 943.25 162.17 943.25 121.11 841.64 121.11 841.64 162.17 870.11 162.17 830.09 201.89 796.15 162.17 819.06 162.17 819.06 121.62 675.37 121.64 675.37 162.17 705.32 162.17 767.85 235.01 699.4 302.79 675.37 302.79" />
              <path className="cls-2" d="M506,276.28c0,12.49,6.92,24.53,25.21,24.53,17.31,0,29.24-13.64,29.24-41V239.48c-4.11.89-8.73,1.34-19.87,3.58C515,247.74,506,261.38,506,276.28Z" />
              <path className="cls-2" d="M1077.35,154.7c-25.87,0-40.43,20.74-40.43,77.38s14.56,77.38,40.43,77.38,40.43-20.74,40.43-77.38S1103.22,154.7,1077.35,154.7Z" />
              <path className="cls-3" d="M0,0V463.93H1400V0ZM282.51,259.46c-52.6-12.47-80.06-28.52-80.06-67.76,0-38.36,31.17-70,81.9-70,36.89,0,64.62,12.94,75.91,27.18V122.14h40v67.74H360.7c-16-23.6-43-34.93-65.16-34.93-18.62,0-24.68,7.46-24.68,17.72,0,13.83,11.18,18.22,53.54,28.48,47,11.59,82.85,26.28,82.85,70.43,0,38.36-29.79,70.88-85.18,70.88-42.4,0-68-10.85-82.9-28.53v28.89h-39V271h38.61C260,299.71,284.33,308.4,312.3,308.4c17.22,0,25.12-6.6,25.12-17.76C337.42,275.48,326.72,269.72,282.51,259.46ZM516.38,150.09c-11.4,0-17.62,1.76-17.62,1.76a35.85,35.85,0,1,1-60.15,26.32c0-43.28,59.63-56.2,105.95-56.2,70,0,90.78,33.89,90.78,77.6V302.79h17.45v40H569.3l0-38h-2.21s-20,37.67-70.93,37.67c-37,0-66-21.3-66-56.53a50.66,50.66,0,0,1,14.52-35.62c18.27-18.28,61.36-32.83,115.76-32.83v-23.8C560.41,168.35,544.43,150.09,516.38,150.09Zm188.94,12.08h-30V121.64l143.69,0v40.55H796.15l33.94,39.72,40-39.72H841.64V121.11H943.26v41.06H920l-67,66.25,63.77,74.37h26.54v40H800.59v-40H826l-35.3-41.3-41.6,41.3H778v40H675.37v-40h24L767.85,235Zm372,180.29c-67.79,0-122.47-44.82-122.47-110.38s54.68-110.38,122.47-110.38,122.48,44.83,122.48,110.38S1145.14,342.46,1077.35,342.46Z" />
            </svg>
          </a>
        </div>

        <div className="footer-content">
          <div className="footer-cell">
            <p className="footer-description">I Saxo elsker vi bøger, og alt det bøger kan betyde for dig og for samfundet. I over 20 år har vi arbejdet for at inspirere dig til at se nye sider af bøger – og måske af livet – hver dag. Sammen med vores kunder og UNICEF gør vi en indsats for, at alle verdens børn skal lære at læse. Vi vil gøre det så nemt som muligt for dig at læse, lytte til og udgive bøger i alle afskygninger - fra forfatteren får sin første idé til du som læser vender den sidste side. Læs Lyt Lev.</p>


            <div className="footer-contact">
              <p className="footer-title">Kontakt & Hjælp</p>
              <p><strong>Saxo.com A/S (CVR: 29197849)</strong></p>
              <p>Telefon: <a href="tel:(+45) 38150510" className="link link--white"> (+45) 38150510</a><br/>Email: <a href="mailto:info@saxo.com" className="link link--white">info@saxo.com</a></p>
            </div>

            <div className="mb-24">
              <p className="footer-title">Sociale medier</p>
              <ul className="social">
                <li><a href="https://www.facebook.com/saxocom" className="icon icon-facebook" target="_blank" rel="noopener">facebook</a></li>
                <li><a href="https://www.instagram.com/saxonyt/" className="icon icon-instagram" target="_blank" rel="noopener">instagram</a></li>
                <li><a href="https://dk.linkedin.com/company/saxo-com" className="icon icon-linkedin" target="_blank" rel="noopener">linkedin</a></li>
                <li><a href="https://www.youtube.com/user/saxocom" className="icon icon-youtube" target="_blank" rel="noopener">youtube</a></li>
              </ul>
            </div>
          </div>
          


          <div className="footer-cell footer-links">
            <ul>
              <li><a className="link-btn link-btn--white" href="/">Kontakt os</a></li>
              <li><a className="link-btn link-btn--white" href="https://www.saxo.com/dk/om-os">Om Saxo</a></li>
              <li><a className="link-btn link-btn--white" href="https://www.saxo.com/dk/om-os#press">Presse</a></li>
              <li><a className="link-btn link-btn--white" href="https://job.saxo.com/">Karriere i Saxo</a></li>
              <li><a className="link-btn link-btn--white" href="/articles/115-kontaktoplysninger">Adresser</a></li>
              <li><a className="link-btn link-btn--white" href="https://www.saxo.com/dk/orderpage/userorders">Følg din ordre</a></li>
              <li><a className="link-btn link-btn--white" href="https://www.saxo.com/dk/gavekort">Gavekort</a></li>
              <li><a className="link-btn link-btn--white" href="/articles/87-sadan-sender-du-retur">Returnering</a></li>
              <li><a className="link-btn link-btn--white" href="/articles/102-cookies">Cookiepolitik</a></li>
              <li><a className="link-btn link-btn--white" href="https://www.saxo.com/dk/cookiedeklaration">Cookiedeklaration</a></li>
              <li><a className="link-btn link-btn--white" href="/articles/100-persondatapolitik">Persondatapolitik</a></li>
              <li><a className="link-btn link-btn--white" href="/articles/103-salgs-og-leveringsbetingelser-for-fysiske-varer">Salgsbetingelser</a></li>
            </ul>

            <ul>
              <li><a className="link-btn link-btn--white" href="https://www.saxo.com/dk/premium">Bliv medlem af Saxo</a></li>
              <li><a className="link-btn link-btn--white" href="https://www.saxo.com/dk/streaming/alle">Bøger i Saxos app</a></li>
              <li><a className="link-btn link-btn--white" href="https://www.saxo.com/dk">Bøger</a></li>
              <li><a className="link-btn link-btn--white" href="https://www.saxo.com/dk/page/lydboger">Lydbøger</a></li>
              <li><a className="link-btn link-btn--white" href="https://www.saxo.com/dk/e-bog">E-bøger</a></li>
              <li><a className="link-btn link-btn--white" href="https://www.saxo.com/dk/side/studieboger">Studiebøger</a></li>
              <li><a className="link-btn link-btn--white" href="https://www.saxo.com/dk/side/erhverv">Erhvervskunder</a></li>
              <li><a className="link-btn link-btn--white" href="https://www.saxo.com/dk/side/unicef">Saxo for UNICEF</a></li>
              <li><a className="link-btn link-btn--white" href="https://www.saxo.com/dk/alle/nye-titler">Nye bogtitler</a></li>
              <li><a className="link-btn link-btn--white" href="https://publish.saxo.com/">Saxo Publish</a></li>
            </ul>
            <MarketingSubscription/>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
