import React from 'react';
import cx from 'classnames';
import { Trans } from '@elevio/kb-kit/lib/components/trans';
import { useArticleFull } from '@elevio/kb-kit/lib/hooks';
import AccessBanner from './AccessBanner';
function ArticleMeta({ className }) {
  const article = useArticleFull();
  if (!article) return null;
  return (
    <>
      <ul className={cx('article-meta', className)}>
        <li className="icon icon-edit">
          <Trans i18nKey="article.writtenBy">
            Written By{' '}
            {{
              name: article.author.name,
            }}
          </Trans>
        </li>
        <li className="icon icon-time">
          <Trans i18nKey="article.lastUpdated">
            Last updated{' '}
            {{
              relativeUpdated: article.lastPublishedAgo,
            }}
          </Trans>
        </li>
      </ul>
      <AccessBanner />
    </>
  );
}
export default ArticleMeta;
