import * as React from 'react';
import { Trans } from '@elevio/kb-kit/lib/components/trans';
import * as Auth from '@elevio/kb-kit/lib/components/auth';
import PageLayout from '../components/layouts/Page';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import ErrorPage from '../components/ErrorPage';
function Page() {
  return (
    <PageLayout header={<Header />} footer={<Footer />}>
      <div className="container container--vertical-padding">
        <ErrorPage title="Login Required">
          <p className="error-message">
            <Trans i18nKey="error.auth">
              Artiklen du prøver at tilgå er kun til brugere der er logget ind. Har du en konto?. 
              <Auth.LoginLink>Login her</Auth.LoginLink>.
            </Trans>
          </p>
        </ErrorPage>
      </div>
    </PageLayout>
  );
}
export default Page;
