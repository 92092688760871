import * as React from 'react';
import * as Article from '@elevio/kb-kit/lib/components/article';
import SearchInput from '../components/searchInput/SearchInput';
import { useTranslation } from '@elevio/kb-kit/lib/hooks';
import PageLayout from '../components/layouts/Page';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import CategoryList from '../components/CategoryList';
import ArticleList from '../components/ArticleList';
import { useBreadcrumbs } from '@elevio/kb-kit/lib/hooks'
import Breadcrumb from '../components/breadcrumbs/Breadcrumbs';
import ArticleMeta from '../components/ArticleMeta';
import ArticleActions from '../components/ArticleActions';
import ArticleFeedback from '../components/ArticleFeedback';
import { MainSection } from '../components/layouts/Main';
import "../css/article-page.scss";
function Page() {
  const { t } = useTranslation();
  return (
    <PageLayout header={<Header />} footer={<Footer />}>
      <div className="container container--vertical-padding">
        <main className="main">
          <article>

            <div className="article-title-group">
              <div>
                <h1 data-testid="article-title"><Article.Title /></h1>
                <Breadcrumb crumbs={useBreadcrumbs()} />
                <ArticleMeta />
              </div>

              <ArticleActions />
            </div>
            
            <Article.Body />
            <ArticleFeedback />
          </article>

          <aside className="side-menu">
            <ArticleList />
          </aside>
        </main>
      </div>
    </PageLayout>
  );
}
export default Page;
