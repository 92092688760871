import React from 'react';

class ChatButtons extends React.Component {
  // Click handler function for button 1
  sendEmail = () => {
    window._dixa("api.showWidget", "{\"show_widget\":true, \"show_contact_form\":true}");
    window._dixa("api.setView", "conversation");
  };

  // Click handler function for button 2
  chatWithUs = () => {
    window._dixa("api.showWidget", "{\"show_widget\":true, \"show_contact_form\":false}");
    window._dixa("api.setView", "conversation");
  };

  render() {
    return (
      <div className="rounded-card">
        <h3>Kontakt via mail</h3>
        <button className="btn btn--red" onClick={this.sendEmail}>Send os en mail</button>
        {/* <button className="btn btn--red" onClick={this.chatWithUs}>Chat med os</button> */}
      </div>
    );
  }
}

export default ChatButtons;