import React from 'react';
import { Helmet } from 'react-helmet';

const DixaChat = () => {
  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`
            (function(window, document, tag, url, name, wid, a, m) {
                window[name] = window[name] || function() {
                  (window[name].q = window[name].q || []).push(arguments);
                  document.getElementById("dixa-widget-wrapper") !== null && document.getElementById("dixa-widget-wrapper").contentWindow.postMessage(JSON.stringify(arguments), "*");
                },
                window[name].l = 1 * new Date(),
                window[name].wid = wid,
                a = document.createElement(tag),
                m = document.getElementsByTagName(tag)[0],
                a.async = 1,
                a.setAttribute("charset", "utf-8"),
                a.src = url,
                m.parentNode.insertBefore(a, m)
            })(window, document, "script", "https://widget.dixa.io/assets/scripts/javascript/loader.js", "_dixa", "f7fabfb4-8fb2-4a78-963c-8805fc3ba3f5");
          `}
        </script>
      </Helmet>
    </>
  );
};

export default DixaChat;