import React, { useState } from 'react';
import "./marketingSubscription.scss";
function MarketingSubscription() {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
	  fetch("https://www.saxo.com/dk/permissionsexternal/setpermissionbyemail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: email,
        permissionAliases: "saxo_main_permission",
        approve: true
      })
    })
    .then(function(response) {
	  // console.log("Response:", response);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then(function(data) {
      setSuccessMessage("Tak! Vi glæder os til at sende dig nyt fra bøgernes verden");
      setErrorMessage('');
      console.log("Success:", data);
    })
    .catch(function(error) {
      setErrorMessage("Error: " + error.message);
      setSuccessMessage('');
      console.error("Error:", error);
    });
  };

  return (
    <div className="marketing-form">
      <p className="marketing-form-title">Boginspiration</p>
      {successMessage && <p className="marketing-form__success">{successMessage}</p>}
      {errorMessage && <p className="marketing-form__error">{errorMessage}</p>}
      <form onSubmit={handleSubmit} className="icon icon-email">
        <input
          className="input-rounded"
          placeholder="Indtast din e-mail"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <button type="submit" className="btn btn--red">Tilmeld dig</button>
      </form>
      
      
      <p>Saxo.com må gerne kontakte mig via e-mail, sms og telefon med tilpassede nyheder og tilbud på print og digitale produkter og tjenester, lyd-, streaming-, kultur- og underholdningstilbud, arrangementer samt mulighed for deltagelse i konkurrencer o.l. fra Saxo.com og fra udvalgte brands og koncernselskaber i JP/Politikens Hus A/S. Listen med brands og koncernselskaber kan løbende opdateres, så der altid præsenteres relevante og aktuelle nyheder og tilbud.<br/><br/>Med mit samtykke accepterer jeg også, at Saxo.com må behandle mine persondata og indsamle information om min interaktion med markedsføringsmeddelelser (såsom åbning og læsning) ved hjælp af tracking pixels og lignende teknologier. Læs mere i vores <a href="https://support.saxo.com/da/articles/100-persondatapolitik" target="_blank" class="link link--white">privatlivspolitik</a> og <a href="https://support.saxo.com/da/articles/102-cookies" target="_blank" class="link link--white">cookiepolitik</a>.<br/><br/>Samtykket kan altid tilbagekaldes via afmeldingslink eller ved at kontakte kundeservice.</p>
      
    </div>
  );
}

export default MarketingSubscription;