import React from 'react';
import { Trans } from '@elevio/kb-kit/lib/components/trans';
import { useArticles } from '@elevio/kb-kit/lib/hooks';
import * as Article from '@elevio/kb-kit/lib/components/article';
import * as Articles from '@elevio/kb-kit/lib/components/articles';
function ArticleList({ className }) {
  const { totalCount } = useArticles();
  if (totalCount === 0) return null;
  return (
    <div className={className}>
      <h3>Andre artikler i kategorien:</h3>
      <ul>
        <Articles.Loop>
          <>
            <Article.IsActive>
              <li className="active"><Article.Title /></li>
            </Article.IsActive>
            <Article.IsNotActive>
              <li>
                <Article.Link className="link link--black">
                  <Article.Title />
                </Article.Link>
              </li>
            </Article.IsNotActive>
          </>
        </Articles.Loop>
      </ul>
    </div>
  );
}
export default ArticleList;
